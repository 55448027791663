// This dictionary contains 14 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var arabicSurveyStrings = {
  pagePrevText: "السابق",
  pageNextText: "التالي",
  completeText: "إرسال البيانات",
  previewText: "معاينة",
  editText: "تعديل",
  startSurveyText: "بداية",
  otherItemText: "نص آخر",
  noneItemText: "لا شيء",
  selectAllItemText: "اختر الكل",
  progressText: "{1} صفحة {0} من",
  // indexText: "{0} of {1}",
  panelDynamicProgressText: "سجل {0} من {1}",
  questionsProgressText: "تمت الإجابة على أسئلة {0} / {1}",
  emptySurvey: "لا توجد صفحة مرئية أو سؤال في النموذج",
  completingSurvey: "شكرا لكم لاستكمال النموذج!",
  completingSurveyBefore: "تظهر سجلاتنا أنك قد أكملت هذا الاستطلاع بالفعل.",
  loadingSurvey: "...يتم تحميل النموذج",
  placeholder: "...اختر",
  // ratingOptionsCaption: "Select...",
  value: "القيمة",
  requiredError: ".يرجى الإجابة على السؤال",
  requiredErrorInPanel: "الرجاء الإجابة على سؤال واحد على الأقل.",
  requiredInAllRowsError: "يرجى الإجابة على الأسئلة في جميع الصفوف",
  numericError: "يجب أن تكون القيمة رقمية.",
  // minError: "The value should not be less than {0}",
  // maxError: "The value should not be greater than {0}",
  textMinLength: "الرجاء إدخال ما لا يقل عن {0} حروف",
  textMaxLength: "الرجاء إدخال أقل من {0} حروف",
  textMinMaxLength: "يرجى إدخال أكثر من {0} وأقل من {1} حروف",
  minRowCountError: "يرجى ملء ما لا يقل عن {0} الصفوف",
  minSelectError: "يرجى تحديد ما لا يقل عن {0} المتغيرات",
  maxSelectError: "يرجى تحديد ما لا يزيد عن {0} المتغيرات",
  numericMinMax: "و'{0}' يجب أن تكون مساوية أو أكثر من {1} وتساوي أو أقل من {2}ا",
  numericMin: "و'{0}' يجب أن تكون مساوية أو أكثر من {1}ا",
  numericMax: "و'{0}' يجب أن تكون مساوية أو أقل من {1}ا",
  invalidEmail: "الرجاء إدخال بريد الكتروني صحيح",
  invalidExpression: "يجب أن يعرض التعبير: {0} 'صواب'.",
  urlRequestError: "طلب إرجاع خطأ '{0}'. {1}ا",
  urlGetChoicesError: "عاد طلب البيانات فارغ أو 'المسار' غير صحيح ",
  exceedMaxSize: "ينبغي ألا يتجاوز حجم الملف {0}ا",
  otherRequiredError: "الرجاء إدخال قيمة أخرى",
  uploadingFile: "تحميل الملف الخاص بك. يرجى الانتظار عدة ثوان والمحاولة لاحقًا",
  loadingFile: "جار التحميل...",
  chooseFile: "اختر الملفات...",
  noFileChosen: "لم تقم باختيار ملف",
  // fileDragAreaPlaceholder: "Drop a file here or click the button below to load the file.",
  confirmDelete: "هل تريد حذف السجل؟",
  keyDuplicationError: "يجب أن تكون هذه القيمة فريدة.",
  addColumn: "أضف العمود",
  addRow: "اضافة صف",
  removeRow: "إزالة صف",
  // emptyRowsText: "There are no rows.",
  addPanel: "اضف جديد",
  removePanel: "إزالة",
  choices_Item: "بند",
  matrix_column: "عمود",
  matrix_row: "صف",
  // multipletext_itemname: "text",
  savingData: "يتم حفظ النتائج على الخادم ...",
  savingDataError: "حدث خطأ ولم نتمكن من حفظ النتائج.",
  savingDataSuccess: "تم حفظ النتائج بنجاح!",
  saveAgainButton: "حاول مجددا",
  timerMin: "دقيقة",
  timerSec: "ثانية",
  timerSpentAll: "لقد أنفقت {0} على هذه الصفحة و {1} إجمالاً.",
  timerSpentPage: "لقد أنفقت {0} على هذه الصفحة.",
  timerSpentSurvey: "لقد أنفقت {0} إجمالاً.",
  timerLimitAll: "لقد أنفقت {0} من {1} في هذه الصفحة و {2} من إجمالي {3}.",
  timerLimitPage: "لقد أنفقت {0} من {1} في هذه الصفحة.",
  timerLimitSurvey: "لقد أنفقت {0} من إجمالي {1}.",
  clearCaption: "واضح",
  // signaturePlaceHolder: "Sign here",
  chooseFileCaption: "اختر ملف",
  removeFileCaption: "قم بإزالة هذا الملف",
  booleanCheckedLabel: "نعم",
  booleanUncheckedLabel: "لا",
  confirmRemoveFile: "هل أنت متأكد أنك تريد إزالة هذا الملف: {0}؟",
  confirmRemoveAllFiles: "هل أنت متأكد أنك تريد إزالة كافة الملفات؟",
  questionTitlePatternText: "عنوان السؤال",
  // modalCancelButtonText: "Cancel",
  // modalApplyButtonText: "Apply",
  // filterStringPlaceholder: "Type to search...",
  // emptyMessage: "No data to display",
  // noEntriesText: "There are no entries yet.\nClick the button below to add a new entry.",
  // more: "More"
};

surveyLocalization.locales["ar"] = arabicSurveyStrings;
surveyLocalization.localeNames["ar"] = "العربية";
