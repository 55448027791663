(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("knockout"), require("survey-core"), require("survey-creator-core"), require("survey-knockout-ui"));
	else if(typeof define === 'function' && define.amd)
		define("SurveyCreator", ["knockout", "survey-core", "survey-creator-core", "survey-knockout-ui"], factory);
	else if(typeof exports === 'object')
		exports["SurveyCreator"] = factory(require("knockout"), require("survey-core"), require("survey-creator-core"), require("survey-knockout-ui"));
	else
		root["SurveyCreator"] = factory(root["ko"], root["Survey"], root["SurveyCreatorCore"], root["SurveyKnockout"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_knockout__, __WEBPACK_EXTERNAL_MODULE_survey_core__, __WEBPACK_EXTERNAL_MODULE_survey_creator_core__, __WEBPACK_EXTERNAL_MODULE_survey_knockout_ui__) {
return 